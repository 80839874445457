<template>
  <div class="list-group list-group-flush list-group-delivery">

      <div
        class="list-group-item list-group-item-action priority-radio-container p-1"
        @click="selectShipping('SHIPPING', false)">
        <div class="selected-icon">
          <i v-if="isSelected('SHIPPING') && !hasDropshipping"
            class="material-icons compact radio-icon">radio_button_checked</i>
          <i v-else class="material-icons compact radio-icon">radio_button_unchecked</i>
        </div>
        <div class="pl-1 mr-2 shipping-label-container">
          <span v-show="!isRushPriority && !usingOnlyProductsDelivery" class="shipping-label-name small">
            {{$t('cart.shipping_using_postnl')}}</span>
          <span v-show="!isRushPriority && usingOnlyProductsDelivery" class="shipping-label-name small">
            {{$t('cart.shipping')}}</span>
          <span v-show="isRushPriority" class="shipping-label-name small">{{$t('cart.shipping_using_postnl')}}</span>
          <div v-show="isSelected('SHIPPING')" class="priority-country-selector mt-1">
            <loading-widget :loading="updatingCountry"/>
            <country-selector
                :value="$store.state.cart.address.delivery_country"
                @update="updateDeliveryCountry"/>
          </div>
        </div>

        <div class="priority-shipping-price border-left pl-2">
          <div class="shipping-label-price text-right pr-1">
            <div>{{showShippingCostsExclVat}}</div>
            <div class="text-muted small">{{showShippingCosts}} incl.</div>
          </div>
        </div>
      </div>

      <!--
      <div v-if="!usingOnlyProductsDelivery"
        class="list-group-item list-group-item-action priority-radio-container p-1"
        @click="selectShipping('SHIPPING', true)">
        <div class="selected-icon">
          <i v-if="isSelected('SHIPPING') && hasDropshipping"
          class="material-icons compact radio-icon">radio_button_checked</i>
          <i v-else class="material-icons compact radio-icon">radio_button_unchecked</i>
        </div>
        <div class="pl-1 mr-2 shipping-label-container">
          <span v-show="!isRushPriority" class="shipping-label-name small">{{$t('cart.dropshipping_using_postnl')}}
            <i class="material-icons compact pointer info-icon float-right"
              @click.prevent.stop="showHelp('cart.dropshipping')">info</i></span>
          <span v-show="isRushPriority" class="shipping-label-name small">{{$t('cart.dropshipping_using_postnl')}}
            <i class="material-icons compact pointer info-icon float-right"
              @click.prevent.stop="showHelp('cart.dropshipping')">info</i></span>

          <div v-show="isSelected('SHIPPING')" class="priority-country-selector mt-1">
            <loading-widget :loading="updatingCountryDropship"/>
            <country-selector
                :value="$store.state.cart.address.delivery_country"
                @update="updateDeliveryCountryDropship"/>
          </div>
        </div>
        <div class="priority-shipping-price border-left pl-2">
          <div class="shipping-label-price text-right pr-1">
            <div>{{showDropshippingCostsExclVat}}</div>
            <div class="text-muted small">{{showDropshippingCosts}} incl.</div>
          </div>
        </div>
      </div>
      -->

      <div v-if="!usingOnlyProductsDelivery"
        class="list-group-item list-group-item-action priority-radio-container p-1"
        @click="selectShipping('PICKUP', false)">
        <div>
          <i v-if="isSelected('PICKUP')" class="material-icons compact radio-icon">radio_button_checked</i>
          <i v-else class="material-icons compact radio-icon">radio_button_unchecked</i>
        </div>
        <div class="pl-1">
          <span class="shipping-label-name small">{{$t('cart.pickup_at_snijlab_in_rotterdam')}}</span>
        </div>
      </div>
  </div>
</template>

<script>
import CountrySelector from 'src/components/tools/CountrySelector';
import LoadingWidget from 'src/components/tools/LoadingWidget';
import TweenTools from 'src/lib/TweenTools';

export default {
  components: {
    CountrySelector,
    LoadingWidget,
  },
  props: {
    shippingMethod: {
      type: String,
      default: null,
    },
    orderId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      updatingCountry: false,
      updatingCountryDropship: false,
      updatingDropshipping: false,
      updatingDelivery: false,
      animatedShipping: null,
      animatedShippingExclVat: null,
      animatedDropshipping: null,
      animatedDropshippingExclVat: null,
    };
  },
  computed: {
    usingOnlyProductsDelivery() {
      return this.$store.state.cart.priorityId === 6;
    },
    hasDropshipping() {
      return this.$store.state.cart.hasDropshipping;
    },
    isRushPriority() {
      return this.$store.state.cart.priorityId === 5;
    },
    shippingCostNum() {
      return (this.$store.state.cart.priorities[this.$store.state.cart.priorityId].shippingCost.amount * 1.21) / 100;
    },
    shippingCostNumExclVat() {
      return this.$store.state.cart.priorities[this.$store.state.cart.priorityId].shippingCost.amount / 100;
    },
    dropshippingCostNum() {
      return ((this.$store.state.cart.priorities[this.$store.state.cart.priorityId].shippingCost.amount
        + this.$config.dropshippingCosts) * 1.21) / 100;
    },
    dropshippingCostNumExclVat() {
      return (this.$store.state.cart.priorities[this.$store.state.cart.priorityId].shippingCost.amount
        + this.$config.dropshippingCosts) / 100;
    },
    showShippingCosts() {
      if (this.animatedShipping !== null) {
        return this.$config.currencyFormat.format(this.animatedShipping);
      }
      return this.$config.currencyFormat.format(this.shippingCostNum);
    },
    showShippingCostsExclVat() {
      if (this.animatedShippingExclVat !== null) {
        return this.$config.currencyFormat.format(this.animatedShippingExclVat);
      }
      return this.$config.currencyFormat.format(this.shippingCostNumExclVat);
    },
    showDropshippingCosts() {
      if (this.animatedDropshipping !== null) {
        return this.$config.currencyFormat.format(this.animatedDropshipping);
      }
      return this.$config.currencyFormat.format(this.dropshippingCostNum);
    },
    showDropshippingCostsExclVat() {
      if (this.animatedDropshippingExclVat !== null) {
        return this.$config.currencyFormat.format(this.animatedDropshippingExclVat);
      }
      return this.$config.currencyFormat.format(this.dropshippingCostNumExclVat);
    },
  },
  watch: {
    updatingDelivery(value) {
      this.$emit('input', value);
    },
    shippingCostNum(value, oldValue) {
      TweenTools.tweenNumber(value, oldValue, this, 'animatedShipping');
    },
    dropshippingCostNum(value, oldValue) {
      TweenTools.tweenNumber(value, oldValue, this, 'animatedDropshipping');
    },
    shippingCostNumExclVat(value, oldValue) {
      TweenTools.tweenNumber(value, oldValue, this, 'animatedShippingExclVat');
    },
    dropshippingCostNumExclVat(value, oldValue) {
      TweenTools.tweenNumber(value, oldValue, this, 'animatedDropshippingExclVat');
    },
  },
  methods: {
    showHelp(item) {
      this.$eventBus.$emit('snlb.showHelp', item);
    },
    selectShipping(deliveryMethod, dropshipping) {
      if (!this.isSelected(deliveryMethod)) {
        this.updatingDelivery = true;
        this.$store.dispatch('updateDeliveryMethod', { deliveryMethod, orderId: this.orderId })
          .then(() => {
            this.updatingDelivery = false;
            if (this.hasDropshipping !== dropshipping) {
              this.updateDropshipping(dropshipping);
            }
          });
      } else if (this.hasDropshipping !== dropshipping) {
        this.updateDropshipping(dropshipping);
      }
    },
    isSelected(shipping) {
      return shipping === this.shippingMethod;
    },
    updateDeliveryCountry(value) {
      this.updatingCountry = true;
      this.$store.dispatch('updateDeliveryCountry', { value, orderId: this.orderId })
        .then(() => {
          this.updatingCountry = false;
        });
    },
    updateDeliveryCountryDropship(value) {
      this.updatingCountryDropship = true;
      this.$store.dispatch('updateDeliveryCountry', { value, orderId: this.orderId })
        .then(() => {
          this.updatingCountryDropship = false;
        });
    },

    updateDropshipping(value) {
      this.updatingDropshipping = true;
      this.$store.dispatch('updateDropshipping', { hasDropshipping: value, orderId: this.orderId })
        .then(() => {
          this.updatingDropshipping = false;
        });
    },
  },
};
</script>

<style lang="scss">
  .list-group-delivery {
    .selected-icon {
      display: flex;
      align-items: center;
    }
    .radio-icon {
      display: inline-block;
    }

    .shipping-label-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: flex-start;
      line-height: normal;
    }
    .shipping-label-name {
      display: inline-block;
    }
    .priority-shipping-price {
      font-size: 0.9em;
      line-height: normal;
    }

    .priority-radio-container {
      display: grid;
      grid-template-columns: 30px auto 110px;
    }

    .list-group-item-action {
      cursor: pointer;
      min-height: 0;
    }

    .priority-country-selector {
      grid-column: 2/4;
      grid-row: 2/3;
      font-size: 0.9rem;
    }
  }
</style>
